<template>
	<goods-detail :is-edit="false" />
</template>

<script>
	import GoodsDetail from './components/detail'

	export default {
		name: 'PublishGoods',
		components: {
			GoodsDetail
		}
	}
</script>
